import React, { useEffect, useState } from "react";
import { ReaderItem } from "../../../models/reader";
import { LoaderSpin } from "../../../middleware/main_loader";

type BookInfoProps = {
  reader: ReaderItem;
};

const BookInfo: React.FC<BookInfoProps> = ({ reader }) => {
  const [bgimage, setBgImage] = useState<undefined | false | string>(false);

  useEffect(() => {
    setBgImage(false);
    ////image loader
    if (reader.images?.poster) {
      const image = new Image();
      image.src = reader.images.poster;
      const showloader = setTimeout(() => {
        setBgImage(undefined);
      }, 1500);
      image.onload = function () {
        clearTimeout(showloader);
        setBgImage(image.src);
      };
      image.onerror = function () {
        clearTimeout(showloader);
        setBgImage(false);
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reader]);

  return (
    <div className="book_section" id="book_info">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            {bgimage ? (
              <img
                src={reader.images?.cover}
                alt="Reader Cover"
                className="img-fluid"
              />
            ) : bgimage === false ? null : (
              <LoaderSpin full={true} align="right" />
            )}
          </div>

          <div className="col-md-6">
            <p className="text-danger">
              This book is no longer available for free.{" "}
              <a
                href="https://flangoo.com/promo"
                className="text-danger text-underline"
              >
                Sign up
              </a>{" "}
              today for the FULL library.
            </p>
            <h1>{reader.title}</h1>
            {reader.categories.findIndex((c) => c.id === "19") >= 0 ? (
              <h4
                style={{
                  color: "#efff00",
                }}
              >
                Limited Time! Available through June 30, 2023
              </h4>
            ) : null}
            {reader.coming_soon ? (
              <div className="alert"> Coming Soon</div>
            ) : null}
            <span className="mr-1 badge badge-dark">{reader.level.name}</span>
            <span className="mr-1 badge badge-dark">
              {reader.chapters} Chapters
            </span>
            <span className="mr-1 badge badge-dark">
              {reader.chapter_questions} Quiz Questions
            </span>
            <span className="mr-1 badge badge-dark">
              {reader.language.name}
            </span>
            <div
              className="mt-3"
              dangerouslySetInnerHTML={{ __html: reader.description_short }}
            ></div>
          </div>
        </div>
      </div>
      <div className="reader_footer row mt-3">
        <div className="col-md-8 col-6 d-flex align-items-center flex-column flex-md-row">
          <p className="text-center text-white main_banner_txt">
            {" "}
            This is one of 200 titles included in your Flangoo library.
            <small className="text-muted d-md-block text-center xs_hidden">
              Copyright © 2005-{new Date().getFullYear()} Teacher's Discovery®,
              a division of American Eagle Co., Inc. All Rights Reserved.
            </small>
          </p>
        </div>
        <div className="col-md-4 col-6">
          <p className=" text-center  text-white">
            Sign up today for the FULL library
          </p>
          <a
            href="https://flangoo.com/promo"
            className="my-2 btn btn-block btn-primary text-white"
          >
            Create Account{" "}
          </a>
        </div>
      </div>
    </div>
  );
};

export default BookInfo;
