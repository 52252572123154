import React, { useContext } from "react";
import * as Sentry from "@sentry/react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";

import ApolloClient from "apollo-boost";

import ScrollToTop from "./middleware/scrollTop";
import { DataContext } from "./context/data-context";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./css/font-awesome.css";
import "./css/font-awesome-pro.css";
import "./css/app.css";

///pages

import PWAOfflineStatus from "./components/common/offline";
import Reader from "./components/pages/reader";
import Page_404 from "./components/pages/page_404";
import HomePage from "./components/pages/homepage";

const App: React.FC = () => {
  const dataContext = useContext(DataContext);
  const graph_url = process.env.REACT_APP_GRAPH_URL;
  const user = dataContext.user;
  const api = new ApolloClient({
    uri: graph_url,
    request: (operation) => {
      if (user && user.token) {
        operation.setContext({
          headers: {
            authorization: user.token ? `Bearer ${user.token}` : "",
            version: process.env.REACT_APP_BUILD_VERSION || false,
            type: user.is_teacher ? "teacher" : "student",
          },
        });
      }
    },
    onError: (q_error) => {
      if (q_error.graphQLErrors) {
        const e_message = q_error.graphQLErrors[0].message;
        if (!e_message) return;
        if (e_message === "NOLOGGEDUSER") {
          localStorage.clear();
          setTimeout(() => {
            // window.location.reload();
          }, 500);
        } else if (e_message === "FORCERELOAD") {
          // window.location.reload();
        } else if (
          e_message === "INACTIVE" &&
          window.location.pathname !== "/youraccount" &&
          window.location.pathname !== "/sign_out" &&
          window.location.pathname !== "/purchaseplan"
        ) {
          if (user && user.is_teacher) {
            window.location.href = "/youraccount";
          } else if (user && !user.is_teacher) {
            localStorage.clear();
            localStorage.setItem(
              "logoutType",
              "Your Teacher's Membership to Flangoo is Inactive"
            );
            setTimeout(() => {
              window.location.href = "/student_signin";
            }, 500);
          }
        }
      }
    },
  });

  return (
    <BrowserRouter>
      <ScrollToTop>
        {
          // @ts-ignore
          <ApolloProvider client={api}>
            <Switch>
              <Route
                path="/:reader_name/:reader_id/:page?"
                component={Reader}
              />
              <Route path="/" exact component={HomePage} />
              <Route component={Page_404} />
            </Switch>
          </ApolloProvider>
        }
      </ScrollToTop>
      <PWAOfflineStatus />
    </BrowserRouter>
  );
};

export default Sentry.withProfiler(App);
